import Head from 'next/head';
import content from '../content.json';
import 'isomorphic-fetch'

export default function Index({ pageData }) {
  return (
      <>
        <Head>
          <title>{content.title}</title>
        </Head>
        <h1>{content.index.title}</h1>
        <div dangerouslySetInnerHTML={{__html: content.index.content}}></div>
      </>
  );
}

export async function getStaticProps() {
  const s3Response = await fetch('https://sphere-assets.msg.com/json/content.json')
  const pageData = await s3Response.json()

  return {
    props: {
      pageData: pageData ? pageData : false
    }
  }
}

